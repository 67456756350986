import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    (function() {
      var unfold = new HSUnfold('.js-hs-unfold-invoker').init();
      var typed = new Typed('#typed', {
        strings: ["Scans", "Edits", "Access"],
        typeSpeed: 75,
        backSpeed: 25,
        backDelay: 2000,
        loop: true,
        loopCount: 3,
        onStringTyped: (arrayPos, self) => {
          var desc = [
            "No limitations on QR code creation or scanning.",
            "Update QR code target anytime, even after printing.",
            "No expiration date. Works forever."
          ]
          document.getElementById('typed-desc').innerHTML = desc[arrayPos];
        }
      });

      if(window.innerWidth > 768){
        new Glider(document.querySelector('.glider'), {
          slidesToShow: 1,
          draggable: false,
          dots: '.glider-dots',
          arrows: {
            prev: '.glider-prev',
            next: '.glider-next'
          }
        });
      }
    })();
  }
}
