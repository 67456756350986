import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    email: String
  };

  connect() {
    posthog.identify(posthog.get_distinct_id(), { email: this.emailValue });
  }
}
